import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { toast } from "react-toastify";

import CreateHotelOwner from "../modal/CreateHotelOwner";
import { deleteAdminAndOwner, getAllAdmin } from "../../services/AuthService";
import Select from "react-select";
import PermissionModal from "../modal/PermissionModal";
import Delete from "../modal/Delete";
import EditHotelOwner from "../modal/EditHotelOwner";
export default function HotelOwner() {
  const [addHotelOwnerShow, setAddHotelOwnerShow] = useState(false);
  const [editHotelOwnerShow, setEditHotelOwnerShow] = useState(false);
  const [permissionShow, setPermissionShow] = useState(false);
  const [permission, setPermission] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  console.log(users, "userhddhih")

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");

  const [editModel, setEditModel] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    value: "hotel_owner",
    label: "Hotel Owner",
  });
  let timeoutId;
  const options = [
    { value: "hotel_owner", label: "Hotel Owner" },
    { value: "admin", label: "Admin" },
  ];
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const limit = 10;

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function getTableData() {
    setLoader(true);
    getAllAdmin(currentPage, limit, search, selectedOption.value)
      .then((response) => {
        setUsers(response.data.result.data);

        setLoader(false);
        const total = response.data.result.meta.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        setLoader(false);
      });
  }

  function onDelete() {
    setLoader(true);

    deleteAdminAndOwner(id)
      .then((response) => {
        getTableData();
        setDeleteModal(false);

        setLoader(false);
        notifyTopRight(response.data.responseMessage);
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error?.response?.data?.responseMessage);
      });
  }

  // const handleSearchChange = (e) => {
  //   const newSearchValue = e.target.value;
  //   setSearch(newSearchValue);

  //   // Clear previous timer if exists
  //   if (searchTimer) {
  //     clearTimeout(searchTimer);
  //   }

  //   // If the search input is empty, make the API call immediately
  //   if (newSearchValue.trim() === "") {
  //     setCurrentPage(0); // Reset currentPage to 0
  //     getTableData();
  //   } else {
  //     // Set a new timer to make the API call after 1 second for non-empty search
  //     setSearchTimer(
  //       setTimeout(() => {
  //         setCurrentPage(0); // Reset currentPage to 0
  //         getTableData();
  //       }, 1000)
  //     );
  //   }
  // };

  const handleSearchChange = (e) => {
    const newSearchValue = e.target.value.trim();
    setSearch(newSearchValue);
  };

  // useEffect(() => {
  //   if (search !== "") {
  //     const delayedSearch = setTimeout(() => {
  //       setCurrentPage(0); // Reset currentPage to 0
  //       getTableData();
  //     }, 1000);

  //     return () => clearTimeout(delayedSearch);
  //   } else {
  //     setCurrentPage(0);
  //     getTableData();
  //   }
  // }, [search]);

  // Debounce function
  // const debounce = (func, delay) => {
  //   let timeoutId;

  //   return function () {
  //     const context = this;
  //     const args = arguments;

  //     clearTimeout(timeoutId);

  //     timeoutId = setTimeout(() => {
  //       func.apply(context, args);
  //       timeoutId = null; // Reset timeoutId after the function call
  //     }, delay);
  //   };
  // };
  // const debouncedGetTableData = useRef(debounce(() => getTableData(), 3000));

  // useEffect(() => {
  //   if (search === "") {
  //     // If search is empty, make the API call immediately
  //     getTableData();
  //   } else {
  //     // Otherwise, debounce the API call
  //     debouncedGetTableData.current();
  //   }

  //   // Cleanup function to cancel the timeout when the component unmounts
  //   return () => clearTimeout(debouncedGetTableData.currentTimeoutId);
  // }, [search, debouncedGetTableData, currentPage, selectedOption.value]);

  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, selectedOption.value]);

  // Create a debounced version of the handleFetch function
  const debouncedHandleFetch = debounce(getTableData, 1000); // Adjust the delay as needed

  useEffect(() => {
    // Reset current page when search or status changes
    setCurrentPage(0);

    // Trigger the debounced handleFetch function when search or status changes
    debouncedHandleFetch();

    // Cleanup function
    return () => {
      // Clear any pending API calls if component unmounts or search/status changes again
      clearTimeout(timeoutId);
    };
  }, [search]);

  // useEffect(() => {
  //   if (search !== "") {
  //     setCurrentPage(0);
  //   }
  // }, [search]);
  // useEffect(() => {
  //   getTableData();
  // }, [currentPage, selectedOption.value]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle
          activeMenu="Admin and Hotel Owner List"
          motherMenu="Admin and Hotel Owner"
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => setAddHotelOwnerShow(true)}
        >
          Create
        </button>
      </div>

      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-end">
              <div className="col-8" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        // onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group mb-0">
                  <label className="mb-2 ">
                    <strong> Select User Type </strong>
                  </label>
                  <Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                    options={options}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>Name</strong>
                  </th>

                  <th>
                    <strong>Email</strong>
                  </th>
                  <th>
                    <strong>Phone Number</strong>
                  </th>
                  <th>
                    <strong>Country</strong>
                  </th>
                  <th>
                    <strong>Permission </strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item?.first_name} {" "}
                      {item?.last_name?.length > 5
                        ? item?.last_name.slice(0, 3) + "..."
                        : item?.last_name}
                    </td>
                    <td>
                      {" "}
                      {item?.email?.length > 10
                        ? item?.email.slice(0, 12) + "..."
                        : item?.email}
                    </td>
                    <td>{item?.number}</td>
                    <td>
                      {item?.country?.length > 10
                        ? item?.country.slice(0, 12) + "..."
                        : item?.country}
                    </td>

                    <td>
                      {item?.permission && item?.user_type === "admin" && (
                        <div
                          className="text-center btn btn-primary mx-3 mt-2 px-3"
                          onClick={() => {
                            setPermissionShow(true);
                            setPermission(item?.permission);
                          }}
                        >
                          View
                        </div>
                      )}
                      {item?.permission && item?.user_type === "hotel_owner" && (
                        <div className="text-center  mx-3 mt-2 px-3">--</div>
                      )}
                    </td>

                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setId(item.id);
                              setDeleteModal(true);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setEditModel(item);
                              setEditHotelOwnerShow(true);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-end">
              <Pagination
                pageCount={pageCount}
                pageValue={currentPage}
                setPage={setCurrentPage}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>

      {loader && <Spinner />}
      {addHotelOwnerShow && (
        <CreateHotelOwner
          addHotelOwnerShow={addHotelOwnerShow}
          onHide={() => setAddHotelOwnerShow(false)}
          hotelOwnerListCall={getTableData}
        />
      )}

      {editHotelOwnerShow && editModel && (
        <EditHotelOwner
          editHotelOwnerShow={editHotelOwnerShow}
          onHide={() => setEditHotelOwnerShow(false)}
          hotelOwnerListCall={getTableData}
          editModelData={editModel}
        />
      )}
      {permissionShow && permission && (
        <PermissionModal
          addHotelOwnerShow={permissionShow}
          onHide={() => setPermissionShow(false)}
          permission={permission}
        />
      )}

      {deleteModal && (
        <Delete
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          callbackfunction={onDelete}
        />
      )}
    </div>
  );
}
